import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"

import logo from "./../../images/prodctos-en-armonia-con-la-naturaleza.png"
import deLogo from "./../../images/flag.png"
import esLogo from "./../../images/spain.png"
import bsLogo from "./../../images/bosnia-and-herzegovina.png"

const Header = () => (
  <header className="is-hidden-touch">
    <div className="container">
      <div className="_inner">
        <div className="columns">
          <div className="column is-narrow _logoLine">
            <div id="_langMenu" className="dropdown is-hoverable">
              <div className="dropdown-trigger">
                <Link to="/es/">
                  <figure className="icon">
                    <img src={esLogo} alt="Language" />
                  </figure>
                </Link>
              </div>
              <div className="dropdown-menu">
                <div className="dropdown-content">
                  <Link to="/" className="dropdown-item animated fadeInUp">
                    Deutsch
                  </Link>
                  <Link
                    to="/bs/"
                    className="dropdown-item animated delay-1s fadeInUp"
                  >
                    Bos/Hr/Srp
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="_mainMenu">
              <div className="columns">
                <div className="column is-narrow">
                  <div className="_leftMenu">
                    <Link
                      to="/es/inspiracion"
                      title="INSPIRADO EN LA SABIDURÍA DE LA NATURALEZA"
                    >
                      Inspiración
                    </Link>
                  </div>
                </div>
                <div className="column is-narrow">
                  <div id="_logoMenu" className="dropdown is-hoverable">
                    <div className="dropdown-trigger">
                      <Link to="/es/">
                        <h1
                          className="_logo title"
                          title="IMMMUNO NATURA - complementos alimenticios y cosméticos"
                        >
                          IMMMUNO NATURA
                        </h1>
                      </Link>
                    </div>
                    <div className="dropdown-menu">
                      <div className="dropdown-content">
                        <Link
                          to="/es/sobre-nosotros"
                          title="SOBRE NOSOTROS Y NUESTRO TRABAJO"
                          className="dropdown-item animated fadeInUp"
                        >
                          Sobre nosotros
                        </Link>
                        <Link
                          to="/es/colaboración"
                          title="HAZTE SOCIO"
                          className="dropdown-item animated delay-1s fadeInUp"
                        >
                          Colaboración
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column is-narrow">
                  <div className="_rightMenu">
                    <Link
                      to="/es/productos"
                      title="SUPLEMENTOS ALIMENTARIOS Y COSMÉTICOS"
                    >
                      Productos
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-narrow _contact">
            <Link to="/es/contacto" title="¡CONTÁCTENOS!">
              Contacto
            </Link>
          </div>
        </div>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
